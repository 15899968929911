(function() {
  angular.module('EntrakV5').controller('meterController', meterController);

  function meterController($scope, $timeout, $interval, $rootScope, Service, $http, Api, KEY, APIKEY, EMS_URL, $state, $stateParams) {
    console.log('meterController');

    var caller = Api.createApiCaller();
    $scope.btnStatus = {};
    $rootScope.hideTabs = false;

    $scope.showHierarchy = false;
    $scope.editingHierarchy = false;
    $scope.editingVirtualDP = false;

    $scope.tenantMap = null;
    $scope.meters = null;
    $scope.hierarchy = [];
    $scope.virtualDataPoints = null;
    $scope.waterVirtualDataPoints = null;
    $scope.hierarchyMeterListType = "ENERGY";//ENERGY or WATER
    $scope.hierarchyVDPListType = "ENERGY";//ENERGY or WATER
    $scope.hierarchyMeterList = null;
    $scope.hierarchyVDPList = null;

    //asume always store the same node objects as in hierarchy
    // if hierarchy reloaded, this need to be reload too
    $scope.expandedNodes = [];

    $scope.day = "1";
    $scope.energyType = "1";
    $scope.menuType = "1";
    $scope.toggleViewMeterFlag = '1';
    $scope.tenantId = null;
    $scope.meter = null;  //meter tab only
    $scope.dataPoint = null;  //meter tab only
    $scope.dataPointRawData = null;  //meter tab only
    $scope.heatmapData = null;  //meter tab only
    $scope.hierarchyDataPoint = null;  //hierarchy tab only
    $scope.loadingMoreVDP = {  //hierarchy tab only
      energy: false,
      water: false,
    }

    $scope.temperatureDevices = null;

    $scope.refreshCount = 0;

    $scope.searchDpText = "";

    $scope.tariffLoaded = false;

    var limit = 25;

    var nameSorter = Service.getSorterIgnoreCase();
    var serialSorter = Service.getSorterIgnoreCase("serial");

    $scope.searchPlaceholder = Service.translate("meter.dataPointName");
    $scope.deviceSearchPlaceholder = Service.translate("meter.deviceSerial");

    $rootScope.loadingPage++;
    caller.call(Api.getDisplayTenants(false, true)).then(function(tenants) {
      tenants.sort(nameSorter);
      tenants.forEach(function(t) {
        t.type = "N_TENANT";
      });

      $scope.tenantDropdown.setDataSource(new kendo.data.DataSource({
        data: tenants
      }));
      $scope.popupTenantDropdown.setDataSource(new kendo.data.DataSource({
        data: tenants
      }));

      $scope.tenantMap = Service.arrayToMap(tenants, "id");
      if (tenants.length) {
        $scope.tenantId = tenants[0].id;
        $scope.loadViewData($scope.showHierarchy, tenants[0]);
      }

      $rootScope.loadingPage--;
    }, function(err) {
      if (err === KEY.ignore)
        return;
      $rootScope.loadingPage--;
      alert(Service.translate("error.generalGetDataFail"));
    });
    $rootScope.loadingPage++;
    caller.call(Api.getTariffs()).then(function(res) {
      res.sort(Service.getSorter());
      let data = new kendo.data.DataSource({
          data: res
      });
      $scope.tariffDropdown1.setDataSource(data);
      $scope.tariffDropdown2.setDataSource(data);
      $scope.tariffLoaded = true;
      $rootScope.loadingPage--;
    }, function(err) {
      if (err === KEY.ignore)
        return;
      $rootScope.loadingPage--;
      alert(Service.translate("error.generalGetDataFail"));
    });

    $scope.reloadTenantHierarchy = function(tenantId) {
      $scope.expandedNodes = [];
      var tenant = $scope.tenantMap[$scope.tenantId];
      $rootScope.loadingPage++;
      caller.call(Api.getHierarchy(tenant.id, tenant.type)).then(function(res) {
        if (res.root.type === "N_TENANT") {
          tenant.hierarchy = res.root.children;
          tenant.hierarchy.sort(nameSorter);
          tenant.hierarchy.sort((a, b) => {
            // 首先，根据type排序，确保N_SITE类型在前
            if (a.type !== b.type) {
              return a.type === "N_SITE" ? -1 : 1;
            }
            // 如果type相同，则根据name排序
            return a.name.localeCompare(b.name);
          });
          tenant.hierarchy.forEach(n => n.isFirstLv = true);
        } else {
          console.error("unexpected hierarchy", res);
        }
        $scope.hierarchy = tenant.hierarchy;
        $rootScope.loadingPage--;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        alert(Service.translate("error.generalGetDataFail"));
      });
    }
    $scope.loadDataPointsByMeter = function(meter) {
      if (meter?.dataPoints || meter.loadingDp)
        return;
      var queryName = null;
      if (meter.__typename === "EnergyMeter") {
        queryName = "getMeter";
      } else if (meter.__typename === "WaterMeter") {
        queryName = "getWaterMeter";
      }
      if (!queryName)
        return;

      meter.loadingDp = true;
      $rootScope.loadingPage++;
      caller.call(Api[queryName](meter.id)).then(function(res) {
        res.dataPoints.sort(nameSorter);
        meter.dataPoints = res.dataPoints;
        $scope.searchMeterDataPoint(meter);
        meter.loadingDp = false;
        $rootScope.loadingPage--;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        meter.loadingDp = false;
        $rootScope.loadingPage--;
        alert(Service.translate("error.generalGetDataFail"));
      });
    }
    $scope.loadViewData = function(isHierarchy, tenant) {
      if (!tenant)
        return;
      $timeout(function() {//act like $scope.$apply
        $scope.virtualDataPoints = null;
        $scope.waterVirtualDataPoints = null;
        $scope.hierarchyDataPoint = null;
        $scope.meters = null;  //keep this null when loading for other place to do checking
        $scope.waterMeters = null;  //keep this null when loading for other place to do checking
        $scope.hierarchy = [];
        $scope.temperatureDevices = null;
        $scope.meter = null;
        $scope.dataPoint = null;
        $scope.chart.setDataSource(new kendo.data.DataSource({
          data: []
        }));
        $scope.editingHierarchy = false;
        $scope.cancelEditVirtualDataPoint();

        $scope.showHierarchy = isHierarchy;

        if (isHierarchy) {
          if (tenant.hierarchy) {
            $scope.hierarchy = tenant.hierarchy;
            $scope.expandedNodes = [];
          } else {
            $scope.reloadTenantHierarchy(tenant.id);
          }
          if (tenant.virtualDataPoints && tenant.waterVirtualDataPoints) {
            $scope.virtualDataPoints = tenant.virtualDataPoints;
            $scope.waterVirtualDataPoints = tenant.waterVirtualDataPoints;
            $scope.toggleHierarchyVDPListType($scope.hierarchyVDPListType);
          } else {
            $rootScope.loadingPage++;
            $scope.hierarchyVDPList = null;
            caller.call([Api.getVirtualDataPoints(tenant.id, limit, 0), Api.getWaterVirtualDataPoints(tenant.id, limit, 0)]).then(function(res) {
              res[0].sort(nameSorter);
              tenant.virtualDataPoints = res[0] || [];
              $scope.virtualDataPoints = res[0] || [];
              tenant.energyVPDOffset = res[0].length;
              if (res[0].length < limit)
                  tenant.energyVPDOffset = -1;

              res[1].sort(nameSorter);
              tenant.waterVirtualDataPoints = res[1] || [];
              $scope.waterVirtualDataPoints = res[1] || [];
              tenant.waterVPDOffset = res[1].length;
              if (res[1].length < limit)
                  tenant.waterVPDOffset = -1;

              $scope.toggleHierarchyVDPListType($scope.hierarchyVDPListType);
              $rootScope.loadingPage--;
            }, function(err) {
              if (err === KEY.ignore)
                return;
              tenant.energyVPDOffset = 0;
              tenant.waterVPDOffset = 0;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
            });
          }
          //load device
          if (tenant.devices) {
            $scope.temperatureDevices = tenant.devices;
          } else {
            $rootScope.loadingPage++;//TODOricky make it like dp?
            $scope.temperatureDevices = null;
            caller.call(Api.getDeviceByApplicationType(tenant.id, [APIKEY.applicationType.aircon, APIKEY.applicationType.thermometer, APIKEY.applicationType.iaqSensor])).then(function(res) {
              res.forEach(d => {
                d.serialId = d.serial;
              });
              $scope.temperatureDevices = res;
              tenant.devices = res;
              $rootScope.loadingPage--;
            }, function(err) {
              if (err === KEY.ignore)
                return;
              $rootScope.loadingPage--;
              alert(Service.translate("error.generalGetDataFail"));
            });
          }
        }
        if (tenant.meters && tenant.waterMeters) {
          $scope.meters = tenant.meters;
          $scope.waterMeters = tenant.waterMeters;
          $scope.searchMetersDataPoint();
          $scope.toggleViewMeter($scope.menuType);
          if (isHierarchy) {
            $scope.toggleHierarchyMeterListType($scope.hierarchyMeterListType);
          }
        } else {
          $rootScope.loadingPage++;
          $scope.hierarchyMeterList = null;
          caller.call([Api.getMeters(tenant.id), Api.getWaterMeters(tenant.id)]).then(function(res) {
            const energyMeters = res[0]||[];
            energyMeters.sort(serialSorter);
            // energyMeters.forEach(m => m?.dataPoints?.sort(nameSorter));
            tenant.meters = energyMeters;
            $scope.meters = energyMeters;

            const waterMeters = res[1]||[];
            waterMeters.sort(serialSorter);
            // waterMeters.forEach(m => m?.dataPoints?.sort(nameSorter));
            tenant.waterMeters = waterMeters;
            $scope.waterMeters = waterMeters;

            $scope.searchMetersDataPoint();
            $scope.toggleViewMeter($scope.menuType);
            if (isHierarchy) {
              $scope.toggleHierarchyMeterListType($scope.hierarchyMeterListType);
            }
            $rootScope.loadingPage--;
          }, function(err) {
            if (err === KEY.ignore)
              return;
            $rootScope.loadingPage--;
            alert(Service.translate("error.generalGetDataFail"));
          });
        }
      });
    }
    $scope.loadMoreVPD = function(isWater, tenant) {
      $scope.loadingMoreVDP[isWater ? "water" : "energy"] = true;
      $timeout(function(){
        caller.call(isWater ? Api.getWaterVirtualDataPoints(tenant.id, limit, tenant.waterVPDOffset) : Api.getVirtualDataPoints(tenant.id, limit, tenant.energyVPDOffset)).then(function(res) {
          if (isWater) {
            tenant.waterVirtualDataPoints.push(...res);
            tenant.waterVPDOffset += res.length;
            if (res.length < limit)
              tenant.waterVPDOffset = -1;
            $scope.loadingMoreVDP.water = false;
          } else {
            tenant.virtualDataPoints.push(...res);
            tenant.energyVPDOffset += res.length;
            if (res.length < limit)
              tenant.energyVPDOffset = -1;
            $scope.loadingMoreVDP.energy = false;
          }
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $scope.loadingMoreVDP[isWater ? "water" : "energy"] = false;
          alert(Service.translate("error.generalGetDataFail"));
        });
      });
    }
    $scope.onScrollVDP = function(){
      var tenant = $scope.tenantMap[$scope.tenantId];
      if ($scope.hierarchyVDPListType === 'WATER' && !$scope.loadingMoreVDP.water && tenant.waterVPDOffset > -1){
        var elm = $("#hierarchyVDPList").get(0);
        if (elm.scrollTop + elm.offsetHeight > elm.scrollHeight - 100)
          $scope.loadMoreVPD(true, tenant);
      } else if ($scope.hierarchyVDPListType === 'ENERGY' && !$scope.loadingMoreVDP.energy && tenant.energyVPDOffset > -1){
        var elm = $("#hierarchyVDPList").get(0);
        if (elm.scrollTop + elm.offsetHeight > elm.scrollHeight - 100)
          $scope.loadMoreVPD(false, tenant);
      }
    }
    $scope.showLoadingMoreVPD = function(){
      return $scope.hierarchyVDPListType === 'WATER' && $scope.loadingMoreVDP.water ||
      $scope.hierarchyVDPListType === 'ENERGY' && $scope.loadingMoreVDP.energy;
    }
    $scope.toggleViewData = function(isHierarchy) {
      if (isHierarchy != $scope.showHierarchy && $scope.tenantId)
        $scope.loadViewData(isHierarchy, $scope.tenantMap[$scope.tenantId]);
    }
    $scope.toggleViewMeterInfo = function(flag){
      $scope.toggleViewMeterFlag = flag
    }
    $scope.toggleViewMeter = function(type) {
      $scope.menuType= type
      $scope.hourlyRawData = null;
      $scope.selectData(null, null);
      // if(type=='1'){
      //   if($scope.meters){
      //     $scope.selectData($scope.meters[0], null);
      //   }
      // }else{
      //   if($scope.waterMeters){
      //     $scope.selectData($scope.waterMeters[0], null);
      //   }
      // }
    }

    $scope.toggleHierarchyMeterListType = function(type) {
      var tmp = type === "ENERGY" ? $scope.meters : $scope.waterMeters;
      if (tmp) {// prevent toggle when loading data
        $scope.hierarchyMeterListType = type;
        $scope.hierarchyMeterList = tmp;
      }
    }
    $scope.toggleHierarchyVDPListType = function(type) {
      var tmp = type === "ENERGY" ? $scope.virtualDataPoints : $scope.waterVirtualDataPoints;
      if (tmp) {// prevent toggle when loading data
        $scope.hierarchyVDPListType = type;
        $scope.hierarchyVDPList = tmp;
      }
    }

    $scope.expandHierarchyMeterList = function(meter){
      meter.hierarchyExpanded = !meter.hierarchyExpanded;
      $scope.loadDataPointsByMeter(meter);
    }

    $scope.refreshMeter = function(id) {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      caller.call(Api.getMeter(id)).then(function(meter){
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        //update meter list (same ref as tenant.meters)
        Service.replaceArrItem($scope.meters, meter);
        meter.dataPoints.sort(nameSorter);
        $scope.searchMetersDataPoint(true);
        //update selection
        if ($scope.meter) {
          if ($scope.meter.id === meter.id) {
            if ($scope.dataPoint) {
              var d = meter.dataPoints.find(dp => dp.id === $scope.dataPoint.id);
              $scope.selectData(meter, d);
            }  
          }
          // var m = $scope.meter.id === meter.id ? meter : $scope.meter;
          // if ($scope.dataPoint) {
          //   var d = meter.dataPoints.find(dp => dp.id === $scope.dataPoint.id) || $scope.dataPoint;
          //   $scope.selectData(m, d);
          // } else {
          //   $scope.selectData(m, null);
          // }
        }
      }, function(e){
        if (e === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        if (e.message) {
          alert(e.message);
        } else {
          alert(Service.translate("error.generalGetDataFail"));
        }
      });
    }

    $scope.tariffDropdownOpt = {
      dataSource: [],
      dataTextField: "name",
      dataValueField: "id"
    }

    $scope.tenantDropdownOpt = {
      dataSource: [],
      dataTextField: "name",
      dataValueField: "id",
      autoWidth: true,
      change: function() {
        $scope.meters=[]
        $scope.waterMeters=[]
        var item = $scope.tenantMap[this.value()];
        $scope.loadViewData($scope.showHierarchy, item);
      }
    }

    function updateCalendarSelection() {
      var d = parseInt($scope.day, 10);
      var dates = [new Date($scope.calendar.value())];
      for (var i = 1; i < d; i++) {
        var tmp = new Date(dates[0]);
        tmp.setDate(tmp.getDate() - i);
        dates.push(tmp);
      }
      dates.reverse();
      $scope.calendar.selectDates(dates);
      $scope.dates = dates;
    }

    var tmpNow = new Date();
    $scope.calendarOpt = {
      footer: false,
      max: tmpNow,
      selectable: 'multiple',
      change: function() {
        $scope.$apply(updateCalendarSelection);
      }
    }
    $timeout(function() {
      $scope.calendar.value(tmpNow);
      updateCalendarSelection();
    });

    $scope.chartOpt = {
      legend: {
        visible: false
      },
      seriesDefaults: {
        padding: 10,
        type: "column",
        stack: false,
        opacity: 1,
        border: {
          width: 0
        },
        overlay: {
          gradient: "none"
        }
      },
      chartArea: {
        background: "",
        margin: 0,
      },
      plotArea: {
        margin: 0,
      },
      categoryAxis: {
        labels: {
          template: "#=kendo.toString(new Date(value*1000), 'htt, d MMM')#",
          rotation: 90,
          font: "10px Arial,Helvetica,sans-serif"
        }
      },
      series: [{
        field: "value",
        categoryField: "time"
      }],
      tooltip: {
        visible: true,
        template: "<div>#=kendo.toString(new Date(category*1000), 'htt, d MMM')#</div><div>#=kendo.toString(value, '\\#\\#,\\#.0000')#</div>"
      }
    }

    $scope.selectData = function(meter, dataPoint) {
      $scope.meter = meter;
      $scope.dataPoint = dataPoint;
    }

    $scope.clickMeter = function(meter) {
      $scope.energyType = 1;
      if ($scope.meter === meter) {
        $scope.selectData(null, null);
      } else {
        $scope.loadDataPointsByMeter(meter);
        $scope.selectData(meter, null);
      }
    }
    $scope.clickWaterMeter = function(meter) {
      $scope.energyType = 2;
      if ($scope.meter === meter) {
        $scope.selectData(null, null);
      } else {
        $scope.loadDataPointsByMeter(meter);
        $scope.selectData(meter, null);
      }
    }


    var gradientColorHigh = [92, 188, 13];
    var gradientColorMid = [255, 235, 84];
    var gradientColorLow = [208, 2, 27];
    function getColorStyle(color1, color2, ratio) {
      if (ratio < 0) {
        ratio = 0;
      } else if (ratio > 1) {
        ratio = 1;
      }

      var rgb = [
        Math.round(color2[0] * ratio + color1[0] * (1 - ratio)),
        Math.round(color2[1] * ratio + color1[1] * (1 - ratio)),
        Math.round(color2[2] * ratio + color1[2] * (1 - ratio))
      ];
      return { "background-color": "rgb(" + rgb + ")" };
    }

    $scope.refreshChartData = function() {
      $scope.refreshCount++;
    }

    $scope.$watch("day", function(n, o) {
      updateCalendarSelection();
    });

    $scope.$watch("energyType", function(n, o) {
      if(n==1){
        $scope.editMeterWin.title(Service.translate("meter.createMeter"));
        $scope.meterData = {
          tenantId: $scope.tenantId,
          dataType: "CUMULATIVE",
          meterType: null,
          dataflowMode: "OLD_DATAFLOW",
          status: "ACTIVE",
          tariffId: null,
        }
      }else{
        $scope.editMeterWin.title(Service.translate("meter.createMeter"));
        $scope.meterData = {
          tenantId: $scope.tenantId,
          meterType: null,
          isEnabled:true,
          tariffId: null,
        }
      }
    });

    //heatmap and chart
    $scope.$watchCollection("[dates, dataPoint, showHierarchy, refreshCount,toggleViewMeterFlag]", function(newArr, oldArr) {

      $scope.dataPointRawData = null;
      $scope.heatmapData = null;
      $scope.selectedTimestamp = null;
      // $scope.toggleViewMeterFlag=2;
      $scope.hourlyRawData = null;
      if (newArr[0] && newArr[1] && !newArr[2]) {
        $rootScope.loadingPage++;
        $rootScope.dataPointLoading=true;
        $scope.chart.setDataSource(new kendo.data.DataSource({
          data: []
        }));
        var start = newArr[0][0];
        start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
        var end = newArr[0][newArr[0].length - 1];
        end = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1, 0, 0, 0, 0);
        start = Service.getUnixTimestamp(start);
        end = Service.getUnixTimestamp(end);
        caller.call($scope.menuType==1?Api.getRawEnergyData(newArr[1].id, start, end):Api.getRawWaterData(newArr[1].id, start, end)).then(function(res) { //api return end time, e.g. 16:00-17:00 will be 17:00
          if (res.datapointId === $scope.dataPoint.id) {
            //raw data
            $scope.dataPointRawData = res.values;

            //process chart/heatmap data
            var min = Number.MAX_VALUE;
            var max = min * -1;
            $scope.dataPointRawData.forEach(function(dp) {
              max = Math.max(max, dp.value);
              min = Math.min(min, dp.value);
            });
            max = Math.max(Math.abs(max), Math.abs(min));

            $scope.nowHourEnd = new Date();
            $scope.nowHourEnd.setHours($scope.nowHourEnd.getHours() + 1, 0, 0, 0);
            $scope.nowHourEnd = Service.getUnixTimestamp($scope.nowHourEnd);
            $scope.heatmapData = [];
            var interval = 3600;
            var cur = start;
            var index = 0;
            while (cur < end) {
              var dpData = $scope.dataPointRawData[index];
              if (!dpData) {
                //missing data at the end
                var missingCount = (end - cur) / interval;
                for (var i = 0; i < missingCount; i++) {
                  var t = cur + i * interval;
                  if ($scope.nowHourEnd > t) {
                    $scope.heatmapData.push({ time: t, noData: true });
                  } else {
                    $scope.heatmapData.push({ time: t, future: true });
                  }
                }
                break;
              } else if (dpData.time === cur) {
                //have data
                if (dpData.value > 0) {
                  dpData.colorStyle = getColorStyle(gradientColorMid, gradientColorHigh, dpData.value / max);
                } else {
                  dpData.colorStyle = getColorStyle(gradientColorMid, gradientColorLow, -dpData.value / max);
                }
                $scope.heatmapData.push(dpData);
                cur += interval;
                index++;
              } else if (dpData.time > cur) {
                //missing data at middle
                var missingCount = (dpData.time - cur) / interval;
                for (var i = 0; i < missingCount; i++) //add empty this round, then next round will add the data
                  $scope.heatmapData.push({ time: (cur + i * interval) });
                cur = dpData.time;
              } else {
                //unexpected
                console.error("unexpected error (start, end, interval, cur, data)", start, end, interval, cur, dpData.time);
                alert("Unexpected data, cannot show heatmap/chart");
                return;
              }
            }

            //show current hour data
            $scope.selectHourRawData($scope.heatmapData[new Date().getHours()]);

            var ds = new kendo.data.DataSource({
              data: $scope.heatmapData
            });
            $scope.chart.setOptions({
              categoryAxis: {
                labels: {
                  step: parseInt($scope.day, 10)
                }
              }
            });
            $scope.chart.setDataSource(ds);
          }
          // if($scope.toggleViewMeterFlag==2&&$scope.dates.length>3){
          //   let timer = window.setTimeout(()=>{
          //     document.documentElement.scrollTop = document.documentElement.scrollHeight;
          //     window.clearTimeout(timer)
          //   },0)
          // }
          $rootScope.dataPointLoading=false;
          $rootScope.loadingPage--;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          alert(Service.translate("error.generalGetDataFail"));
        });
      }
    });

    $scope.pendingRecap = "-";
    function checkPending(){
      $rootScope.loadingPage++;
      caller.call(Api.getRecapQueue()).then(function(res) {
        $scope.pendingRecap = res.total;
        $rootScope.loadingPage--;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
      });
    }
    checkPending();
    var queryTimer = $interval(checkPending, 60000);

    $scope.selectedTimestamp = null;
    $scope.hourlyRawData = null;
    $scope.selectHourRawData = function(d) {
      if (d.noData) {
        $scope.hourlyRawData = [];
        $scope.selectedTimestamp = d.time;
        return;
      } else if (d.future) {
        return;
      }

      $rootScope.loadingPage++;
      $scope.hourlyRawData = null;
      var start = d.time;
      $scope.selectedTimestamp = start;
      var end = start + 60 * 60;
      var id = $scope.dataPoint.id;

      caller.call( $scope.menuType==1?Api.getHourRawEnergyData(id, start, end):Api.getHourRawWaterData(id, start, end)).then(function(res) { //api return end time, e.g. 16:00-17:00 will be 17:00
        if (res.datapointId === id) {
          $scope.hourlyRawData = res.values;
          $scope.selectedTimestamp = start;
        }
        $rootScope.loadingPage--;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        alert(Service.translate("error.generalGetDataFail"));
      });
    }

    $scope.meterData = {};
    $scope.editWinOpt = {
      width: "640px",
      modal: true,
      draggable: false,
      visible: false,
      resizable: false,
      open: function() {
        $scope.$apply(function() {
          $scope.btnStatus.saving = false;
        });
      }
    }
    $scope.popupTenantDropdownOpt = {
      dataSource: [],
      dataTextField: "name",
      dataValueField: "id"
    }
    $scope.meterStatusDropdownOpt = {//"NO_METERSTATUS",
      dataSource: ["ACTIVE", "INACTIVE", "INSTALLED", "IN_STOKC"],
    }
    $scope.dataTypeDropdownOpt = {//"NO_DATATYPE",
      dataSource: ["CUMULATIVE", "DELTA", "MIXED"],
    }
    $scope.meterTypeDropdownOpt = {//"NO_METERTYPE", "DEFAULT"
      dataSource: ["EGAUGE", "BMS", "ACREL"],
    }

    $scope.waterMeterTypeDropdownOpt = {//"NO_METERTYPE", "DEFAULT"
      dataSource: ["BMS"],
    }

    $scope.dataflowModeDropdownOpt = {//"NO_DATAFLOWMODE",
      dataSource: ["OLD_DATAFLOW", "EGAUGE_METER_TO_MANGOPIE", "HUB_METER_TO_MANGOPIE"],
    }
    $scope.bmsTypeDropdownOpt = {
      dataSource: [{ id: "BACNET", name: Service.translate("meter.bacnet") }, { id: "MODBUS", name: Service.translate("meter.modbus") }],
      dataTextField: "name",
      dataValueField: "id"
    }
    $scope.dataTransmissionUnitDropdownOpt = {
      dataSource: ['NO', 'TCP', 'RTU']
    }
    //xml for test: en-trak1272.d.en-trak.com
    $scope.createMeter = function() {
      $scope.isEdit = false
      $scope.energyType = '1'
      $scope.editMeterWin.title(Service.translate("meter.createMeter"));
      $scope.meterData = {
        tenantId: $scope.tenantId,
        dataType: "CUMULATIVE",
        meterType: null,
        dataflowMode: "OLD_DATAFLOW",
        status: "ACTIVE",
        remark: '',
        tariffId: null,
      }
      setTimeout(function() {
        $scope.editMeterWin.open().center();
      });
    }
    $scope.confirmCreateMeter = function() {
      $rootScope.loadingPage++;
      var xml, bmsType, dataUnit, dataTopic, heartbeatTopic, payloadParser;
      if ($scope.meterData.meterType === "EGAUGE") {
        xml = $scope.meterData.xmlUrl;
      } else if ($scope.meterData.meterType === "BMS") {
        bmsType = $scope.meterData.bmsType;
        dataUnit = $scope.meterData.dataTransmissionUnit;
      } else if ($scope.meterData.meterType === "ACREL") {
        dataTopic = $scope.meterData.dataTopic;
        heartbeatTopic = $scope.meterData.heartbeatTopic;
        payloadParser = $scope.meterData.payloadParser;
      }
      caller.call(Api.createMeter($scope.meterData.tenantId, $scope.meterData.serial, $scope.meterData.status, $scope.meterData.description,
          $scope.meterData.hasKva, $scope.meterData.dataflowMode, $scope.meterData.dataType, $scope.meterData.meterType,
          xml, bmsType, dataUnit, dataTopic, heartbeatTopic, payloadParser, $scope.meterData.tariffId, $scope.meterData.remark)).then(function(res) {
        $scope.tenantMap[res.tenantId].meters.push(res);
        $scope.searchMetersDataPoint();
        $rootScope.loadingPage--;
        $scope.editMeterWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.confirmCreateWaterMeter = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      let params = {
        tenantId: $scope.meterData.tenantId,
        serial: $scope.meterData.serial,
        description: $scope.meterData.description,
        meterType: $scope.meterData.bmsType,
        remark: $scope.meterData.remark,
        isEnabled: $scope.meterData.isEnabled,
        tariffId: $scope.meterData.tariffId
      }
      caller.call(Api.createWaterMeter(params)).then(function(res) {
        $scope.tenantMap[res.tenantId].waterMeters.push(res);
        $scope.waterMeters = $scope.tenantMap[res.tenantId]?.waterMeters||[]
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        $scope.editMeterWin.close();
      }, function(err) {
        $scope.btnStatus.saving = false;
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }

      });
    }

    $scope.menuOpt = {
      anchor: $("#meter #deviceInfoMap .tooltipAnchor div"),
      origin: "top left",
      position: "top right",
    }

    $scope.menuSelectedMeter = null;
    $scope.openMeterMenu = function(meter,enType, $event) {
      $scope.energyType = enType
      var pos = window.innerHeight - $event.clientY < 120 ? "bottom right" : "top right";
      $event.stopPropagation();
      $scope.menuSelectedMeter = meter;
      $scope.meterMenu.setOptions({ anchor: $("#meter .meterTree #anchor" + meter.id + " .editBtn"), position: pos });
      $scope.meterMenu.open();
    }

    $scope.updateMeter = function() {
      $scope.meterMenu.close();
      $scope.editMeterWin.title(Service.translate("meter.updateMeter"));
      $scope.isEdit = true
      $scope.meterData = {
        id: $scope.menuSelectedMeter.id,
        tenantId: $scope.menuSelectedMeter.tenantId,
        currentTenantId: $scope.menuSelectedMeter.tenantId,
        dataType: $scope.menuSelectedMeter.dataType,
        meterType: $scope.meterTypeDropdownOpt.dataSource.indexOf($scope.menuSelectedMeter.meterType) > -1 ? $scope.menuSelectedMeter.meterType : null,
        dataflowMode: $scope.menuSelectedMeter.dataflowMode,
        status: $scope.menuSelectedMeter.status,
        serial: $scope.menuSelectedMeter.serial,
        description: $scope.menuSelectedMeter.description,
        hasKva: $scope.menuSelectedMeter.hasKva,
        isEnabled: $scope.menuSelectedMeter?.isEnabled||null,
        remark:$scope.menuSelectedMeter?.remark||'',
        tariffId: $scope.menuSelectedMeter.tariff
      }
      if($scope.energyType == 2){ //water meter.meterType = $scope.meterData.bmsType
        $scope.meterData.bmsType = $scope.menuSelectedMeter.meterType
      }
      if ($scope.menuSelectedMeter.meterType === "EGAUGE") {
        $scope.meterData.xmlUrl = ($scope.menuSelectedMeter.egaugeMeter ? $scope.menuSelectedMeter.egaugeMeter.xmlUrl : "");
      } else if ($scope.menuSelectedMeter.meterType === "BMS") {
        $scope.meterData.bmsType = ($scope.menuSelectedMeter.bmsMeter ? $scope.menuSelectedMeter.bmsMeter.type : null);
        $scope.meterData.dataTransmissionUnit = ($scope.menuSelectedMeter.bmsMeter ? $scope.menuSelectedMeter.bmsMeter.dataTransmissionUnit : null);
      } else if ($scope.menuSelectedMeter.meterType === "ACREL") {
        if ($scope.menuSelectedMeter.acrelMeter) {
          $scope.meterData.dataTopic = $scope.menuSelectedMeter.acrelMeter.dataTopic;
          $scope.meterData.heartbeatTopic = $scope.menuSelectedMeter.acrelMeter.heartbeatTopic;
          $scope.meterData.payloadParser = $scope.menuSelectedMeter.acrelMeter.payloadParser;
        } else {
          $scope.meterData.dataTopic = "";
          $scope.meterData.heartbeatTopic = "";
          $scope.meterData.payloadParser = "";
        }
      }
      setTimeout(function() {
        $scope.editMeterWin.open().center();
      });
    }
    $scope.confirmUpdateMeter = function() {
      $rootScope.loadingPage++;
      var oldTenantId = $scope.meterData.currentTenantId;
      var meterId = $scope.meterData.id;
      var xml, bmsType, dataUnit, dataTopic, heartbeatTopic, payloadParser;
      if ($scope.meterData.meterType === "EGAUGE") {
        xml = $scope.meterData.xmlUrl;
      } else if ($scope.meterData.meterType === "BMS") {
        bmsType = $scope.meterData.bmsType;
        dataUnit = $scope.meterData.dataTransmissionUnit;
      } else if ($scope.meterData.meterType === "ACREL") {
        dataTopic = $scope.meterData.dataTopic;
        heartbeatTopic = $scope.meterData.heartbeatTopic;
        payloadParser = $scope.meterData.payloadParser;
      }
      caller.call(Api.updateMeter(meterId, $scope.meterData.tenantId, $scope.meterData.serial, $scope.meterData.status, $scope.meterData.description, $scope.meterData.hasKva, $scope.meterData.dataflowMode, $scope.meterData.dataType, $scope.meterData.meterType,
          xml, bmsType, dataUnit, dataTopic, heartbeatTopic, payloadParser, $scope.meterData.tariffId, $scope.meterData.remark)).then(function(res) {
        //update the showing meter/dataPoint
        if ($scope.meter && $scope.meter.id === meterId) {
          if (res.tenantId !== $scope.tenantId) {
            $scope.selectData(null, null);
          } else if ($scope.dataPoint) {
            var dp = res.dataPoints.find(d => d.id === $scope.dataPoint.id);
            if (dp) {
              $scope.selectData(res, dp);
            } else {
              $scope.selectData(res, null);
            }
          } else {
            $scope.selectData(res, null);
          }
        }
        //update tenant.meters
        if (res.tenantId !== oldTenantId) {
          Service.deleteArrItem($scope.tenantMap[oldTenantId].meters, meterId);
        }

        Service.replaceArrItem($scope.tenantMap[res.tenantId].meters, res, true);
        $scope.searchMetersDataPoint();
        $rootScope.loadingPage--;
        $scope.editMeterWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }
    $scope.confirmUpdateWaterMeter = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      var oldTenantId = $scope.meterData.currentTenantId;
      var meterId = $scope.meterData.id;
      let params = {
        tenantId:$scope.meterData.tenantId,
        serial:$scope.meterData.serial,
        description:$scope.meterData.description,
        meterType:$scope.meterData.bmsType,
        remark:$scope.meterData.remark,
        isEnabled:$scope.meterData.isEnabled,
        tariffId:$scope.meterData.tariffId
      }
      caller.call(Api.updateWaterMeter(meterId, params)).then(function(res) {
        //update the showing meter/dataPoint
        if ($scope.meter && $scope.meter.id === meterId) {
          if (res.tenantId !== $scope.tenantId) {
            $scope.selectData(null, null);
          } else if ($scope.dataPoint) {
            var dp = res?.dataPoints?.find(d => d.id === $scope.dataPoint.id);
            if (dp) {
              $scope.selectData(res, dp);
            } else {
              $scope.selectData(res, null);
            }
          } else {
            $scope.selectData(res, null);
          }
        }
        //update tenant.meters
        if (res.tenantId !== oldTenantId) {
          Service.deleteArrItem($scope.tenantMap[oldTenantId].waterMeters, meterId);
        }
        Service.replaceArrItem($scope.tenantMap[res.tenantId].waterMeters, res, true);
        $scope.searchMetersDataPoint();
        $scope.btnStatus.saving = false;
        $rootScope.loadingPage--;
        $scope.editMeterWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
        $scope.btnStatus.saving = false;
      });
    }
    $scope.confirmSaveMeter = function() {
      if($scope.energyType=='1'){
        if ($scope.meterData.id) {
          $scope.confirmUpdateMeter();
        } else {
          $scope.confirmCreateMeter();
        }
      }else{
        if ($scope.meterData.id) {
          $scope.confirmUpdateWaterMeter();
        } else {
          $scope.confirmCreateWaterMeter();
        }
      }
    }

    $scope.deleteMeter = function() {
      $scope.meterMenu.close();
      if($scope.energyType==1){
        var m = $scope.menuSelectedMeter;
        $rootScope.deletePopup.show("meter.deleteMeter", "meter.deleteMeterDesc", m.id, function() {
          caller.call(Api.deleteMeter(m.id)).then(function(res) {
            if ($scope.meter === m)
              $scope.selectData(null, null);
            Service.deleteArrItem($scope.tenantMap[$scope.menuSelectedMeter.tenantId].meters, m.id);
            $rootScope.deletePopup.close();
          });
        }, function(err) {
          if (err === KEY.ignore)
            return;
          alert(Service.translate("error.generalDeleteFail"));
        });
      }else{// delete water meter
        var m = $scope.menuSelectedMeter;
        $rootScope.deletePopup.show("meter.deleteMeter", "meter.deleteMeterDesc", m.id, function() {
          caller.call(Api.deleteWaterMeter(m.id)).then(function(res) {
            if ($scope.meter === m)
              $scope.selectData(null, null);
            Service.deleteArrItem($scope.tenantMap[$scope.menuSelectedMeter.tenantId].waterMeters, m.id);
            $rootScope.deletePopup.close();
          });
        }, function(err) {
          if (err === KEY.ignore)
            return;
          alert(Service.translate("error.generalDeleteFail"));
        });
      }
    }

    $scope.onSelectFile = function(inputFile){
      if (!window.File || !window.FileReader){
        console.log("not supported");
        return;
      }

      if (!inputFile.files || !inputFile.files[0])
        return;

      var f = inputFile.files[0];
      if (f.size > 10485760 || f.fileSize > 10485760){//10MB limit
        alert("uploadCtrl.max10Mb");
        return;
      }

      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;

      var m = $scope.menuSelectedMeter;
      if ($scope.energyType == 1){
        var uploadFunc = Api.uploadMeterData;
      } else {
        var uploadFunc = Api.uploadWaterMeterData;
      }

      var fileReader = new FileReader();
      fileReader.onload = function(e){
        caller.call(uploadFunc(m.serial, new Date().getTimezoneOffset() * -1, f)).then(function(h) {
          $rootScope.loadingPage--;
          $scope.btnStatus.saving = false;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          $scope.btnStatus.saving = false;
          if (err.message) {
            alert(err.message);
          } else {
            alert(Service.translate("error.generalUpdate"));
          }
        });
      }
      fileReader.readAsDataURL(f);

      inputFile.value = null;
    }
    $scope.uploadMeterData = function(){
      $scope.meterMenu.close();
      var m = $scope.menuSelectedMeter;
      $rootScope.confirmPopup.show("meter.upload", "meter.uploadDesc", m.serial, function(){
        $('#meter #meterDataFileChooser').click();
        $rootScope.confirmPopup.close();
       });
    }

    $scope.searchMeterDataPoint = function(meter) {
      if (!meter)
        return;
      var txt = $scope.searchDpText.trim().toLowerCase();
      if (txt) {
        meter.searchFound = false;
        if (meter.dataPoints) {
          meter.dataPoints.forEach(function(dp) {
            if (dp.name.toLowerCase().includes(txt)) {
              dp.searchFound = true;
              meter.searchFound = true;
            } else {
              dp.searchFound = false;
            }
          });
        }
      } else {
        meter.searchFound = true;
        if (meter.dataPoints) {
          meter.dataPoints.forEach(dp => dp.searchFound = true);
        }
      }
    }
    //TODOricky here
    $scope.searchMetersDataPoint = function(keepSelection) {
      if ($scope.meters) {
        //update search result
        $scope.meters.forEach($scope.searchMeterDataPoint);
        //expand/collapse meters
        if (!keepSelection) {
          if ($scope.meter && !$scope.meter.searchFound) {
            $scope.selectData(null, null);
          } else if ($scope.dataPoint && !$scope.dataPoint.searchFound) {
            $scope.selectData($scope.meter, null);
          }
          var txt = $scope.searchDpText.trim().toLowerCase();
          if (txt && !$scope.meter) {
            var firstMatch = $scope.meters.find(m => m.searchFound);
            $scope.selectData(firstMatch, null);
          }
        }
      }
      if ($scope.waterMeters) {
        //update search result
        $scope.waterMeters.forEach($scope.searchMeterDataPoint);
      }
    }

    $scope.meterTooltipOpt = {
        filter: "[data-desc]",
        position: "left top",
        width: "auto",
        callout: false,
        animation: {
            open: {
                effects: "zoom",
                duration: 150
            }
        },
        content: function(e) {
            return e.target.data("desc") || "-";
        },
        // show: function(e) {
        //     e.sender.popup.element.css({"margin-top": "-5px"});
        // }
    }

    var searchTimer = null;
    $scope.searchDpChanged = function() {
      $timeout.cancel(searchTimer);
      searchTimer = $timeout($scope.searchMetersDataPoint, 300);
    }

    $scope.dateRangeData = {};
    $scope.dateRangeWinOpt = {
      title: Service.translate("meter.recapData"),
      width: "640px",
      modal: true,
      draggable: false,
      visible: false,
      resizable: false,
      open: function() {
        $scope.$apply(function() {
          $scope.btnStatus.recaping = false;
        });
      }
    }

    var now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    now.setMinutes(0);
    function updateRange() {
      now = new Date();
      now.setSeconds(0);
      now.setMilliseconds(0);
      now.setMinutes(Math.floor(now.getMinutes() / 10) * 10);
      $scope.startPicker.setOptions({ max: now });
      $scope.endPicker.setOptions({ max: now });
      $scope.recalcStartPicker.setOptions({ max: now });
      $scope.recalcEndPicker.setOptions({ max: now });
      return now;
    }
    $scope.pickerStartOpt = {
      max: now,
      interval: 10,
      timeFormat: "h:mm tt",
      change: function() {
        $timeout(function() {
          var start = $scope.dateRangeData.start.getTime();
          var endMax = new Date(Math.min(start + 604800000, now.getTime()));// 7 days
          if ($scope.dateRangeData.end) {
            var end = $scope.dateRangeData.end.getTime();
            if (start > end) {
              $scope.dateRangeData.end = new Date(endMax);
            } else if (end - start > 604800000) {
              $scope.dateRangeData.end = endMax;
            }
          }
          $scope.endPicker.min(new Date(start));
          $scope.endPicker.max(endMax);
          $scope.recalcEndPicker.min(new Date(start));
          $scope.recalcEndPicker.max(endMax);
        }, 100);
      },
      open: updateRange
    }
    $scope.pickerEndOpt = {
      max: now,
      interval: 10,
      timeFormat: "h:mm tt",
      open: updateRange
    }
    $scope.recalcEmsData = function() {
      $scope.recalcWin.title(Service.translate("meter.recalcEmsData"));
      var maxDate = updateRange();
      var dates = $scope.calendar.selectDates();
      var start = dates[0];
      var end = new Date(dates[dates.length-1]);
      end.setDate(end.getDate() + 1);  //next day 00:00am
      if (end.getTime() > maxDate.getTime())
        end = maxDate;
      $scope.recalcEndPicker.min(start);
      $scope.dateRangeData.end = end;
      $scope.dateRangeData.start = start;
      $scope.dateRangeData.tenantId = $scope.tenantId;
      $scope.dateRangeData.target = $scope.tenantMap[$scope.tenantId].name;
      $timeout(function() {
        $scope.recalcWin.open().center();
      });
    }
    $scope.confirmRecalc = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.recaping = true;
      $http.post(EMS_URL + '/file/recap/dailykwhusage', {
        CorpCode: $scope.tenantMap[$scope.tenantId].code,
        st: Service.dateFmt($scope.dateRangeData.start, "yyyy-MM-dd"),
        et: Service.dateFmt($scope.dateRangeData.end, "yyyy-MM-dd")
      }).then(function(res) {
        $rootScope.loadingPage--;
        $scope.btnStatus.recaping = false;
        $scope.recalcWin.close();
      }, function(err){
        $rootScope.loadingPage--;
        $scope.btnStatus.recaping = false;
        alert(Service.translate("error.generalUpdate"));
      });
    }
    $scope.recapMeterData = function(byMeter) {
      $scope.meterMenu.close();
      $scope.recapWin.title(Service.translate("meter.recapData"));
      var maxDate = updateRange();
      var dates = $scope.calendar.selectDates();
      var start = dates[0];
      var end = new Date(dates[dates.length-1]);
      end.setDate(end.getDate() + 1);  //next day 00:00am
      if (end.getTime() > maxDate.getTime())
        end = maxDate;
      $scope.endPicker.min(start);
      $scope.dateRangeData.end = end;
      $scope.dateRangeData.start = start;
      $scope.dateRangeData.tenantId = $scope.tenantId;
      if (byMeter) {
        $scope.dateRangeData.target = $scope.menuSelectedMeter.serial;
        $scope.dateRangeData.meterId = $scope.menuSelectedMeter.id;
        $scope.dateRangeData.recapQueues = [$scope.energyType == 1 ? "recapMeter" : "recaptureWaterMeter"];
      } else {
        $scope.dateRangeData.target = $scope.tenantMap[$scope.tenantId].name;
        $scope.dateRangeData.meterId = null;
        $scope.dateRangeData.recapQueues = ["recapMeter", "recaptureWaterMeter"];
      }
      $timeout(function() {
        $scope.recapWin.open().center();
      });
    }
    $scope.confirmRecap = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.recaping = true;
      caller.call($scope.dateRangeData.recapQueues.map(q => Api[q]($scope.dateRangeData.tenantId, $scope.dateRangeData.meterId,
          Service.getUnixTimestamp($scope.dateRangeData.start),
          Service.getUnixTimestamp($scope.dateRangeData.end)))).then(function(res) {
        $rootScope.loadingPage--;
        $scope.btnStatus.recaping = false;
        $scope.recapWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.recaping = false;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }
    $scope.recapHourData = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.recaping = true;
      let recap = null
      if($scope.menuType==1){
        recap=Api.recapMeter($scope.tenantId, $scope.meter.id, $scope.selectedTimestamp, $scope.selectedTimestamp+60*60)
      }else{
        recap=Api.recaptureWaterMeter($scope.tenantId, $scope.meter.id, $scope.selectedTimestamp, $scope.selectedTimestamp+60*60)
      }
      caller.call(recap).then(function(res) {
        $rootScope.loadingPage--;
        $scope.btnStatus.recaping = false;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.recaping = false;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    //edit datapoint
    $scope.menuSelectedDataPoint = null;
    $scope.openDataPointMenu = function(meter, dataPoint, $event) {
      var pos = window.innerHeight - $event.clientY < 120 ? "bottom right" : "top right";
      $event.stopPropagation();
      $scope.menuSelectedDataPoint = dataPoint;
      $scope.menuSelectedMeter = meter;
      $scope.dataPointMenu.setOptions({ anchor: $("#meter .meterTree #anchor" + dataPoint.id + " .editBtn"), position: pos });
      $scope.dataPointMenu.open();
    }

    $scope.dataPointData = {};
    $scope.dataPointStatusDropdownOpt = {//"NO_DATAPOINTTATUS", 
      dataSource: ["ENABLE", "DISABLE"]
    }
    $scope.dataPointModbusRegisterTypeDropdownOpt = {
      dataSource: ["Float", "U16","U32","S16","S32"]
    }
    $scope.dataPointBacnetObjectTypeDropdownOpt = {
      dataSource: ["ANALOG_INPUT", "ANALOG_OUTPUT","ANALOG_VALUE"]
    }
    $scope.createDataPoint = function() {
      $scope.meterMenu.close();
      $scope.editDataPointWin.title(Service.translate("meter.createDataPoint"));
      $scope.dataPointData = {
        meterId: $scope.menuSelectedMeter.id,
        scaler: 1,
        name: "",
        status: "ENABLE",
        scalerInHub: false,
        isGenerator: false
      }
      setTimeout(function() {
        $scope.editDataPointWin.open().center();
      });
    }
    $scope.updateDataPoint = function() {
      $scope.dataPointMenu.close();
      $scope.editDataPointWin.title(Service.translate("meter.updateDataPoint"));
      $scope.dataPointData = {
        id: $scope.menuSelectedDataPoint.id,
        scaler: $scope.menuSelectedDataPoint.scaler,
        name: $scope.menuSelectedDataPoint.name,
        status: $scope.menuSelectedDataPoint.status,
      }
      if($scope.energyType==2){
        const registerType = $scope.menuSelectedDataPoint?.modbus?.registerType||''
        $scope.dataPointData={
          ...$scope.dataPointData,
          meterId:$scope.menuSelectedDataPoint?.meterId||null,
          status:$scope.menuSelectedDataPoint.isEnabled?'ENABLE':'DISABLE',
          remark:$scope.menuSelectedDataPoint.remark,
          bacnet:{...$scope.menuSelectedDataPoint.bacnet},
          modbus:{
            ...$scope.menuSelectedDataPoint.modbus,
            registerType:registerType.charAt(0).toUpperCase() + registerType.slice(1).toLowerCase()
          },
        }
      }
      setTimeout(function() {
        $scope.editDataPointWin.open().center();
      });
    }

    $scope.confirmCreateDataPoint = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      var mId = $scope.dataPointData.meterId;
      var meter = $scope.menuSelectedMeter;
      caller.call(Api.createDataPoint(mId, $scope.dataPointData.status, $scope.dataPointData.name, $scope.dataPointData.scaler, $scope.dataPointData.scalerInHub, $scope.dataPointData.isGenerator)).then(function(res) {
        if (meter.dataPoints)
          meter.dataPoints.push(res);
        $scope.searchMetersDataPoint();
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        $scope.editDataPointWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }
    $scope.confirmCreateWaterDataPoint = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      var meter = $scope.menuSelectedMeter;
      let params = {
        name:$scope.dataPointData?.name||'',
        meterId:$scope.dataPointData.meterId,
        scaler:$scope.dataPointData.scaler,
        remark:$scope.dataPointData.remark,
        isEnabled:$scope.dataPointData.status=="ENABLE"?true:false
      }
      if($scope.meter?.meterType == "BACNET"){
        params = {
          ...params,
          bacnet:{
            objectId:$scope.dataPointData?.bacnet?.objectId,
            objectType:$scope.dataPointData?.bacnet?.objectType,
          }
        }
      }
      if($scope.meter?.meterType == "MODBUS"){
        params = {
          ...params,
          modbus:{
            slaveId:$scope.dataPointData?.modbus?.slaveId,
            registerId:$scope.dataPointData?.modbus?.registerId,
            registerType:$scope.dataPointData?.modbus?.registerType,
          }
        }
      }
      caller.call(Api.createWaterDatapoint(params)).then(function(res) {
        if (meter.dataPoints)
          meter.dataPoints.push(res);
        $scope.searchMetersDataPoint();
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        $scope.editDataPointWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }
    $scope.confirmUpdateWaterDataPoint = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      var id = $scope.dataPointData.id;
      var meter = $scope.menuSelectedMeter;
      let params = {
        name:$scope.dataPointData?.name||'',
        meterId:$scope.dataPointData.meterId,
        scaler:$scope.dataPointData.scaler,
        // serial:$scope.dataPointData.serial,
        remark:$scope.dataPointData.remark,
        isEnabled:$scope.dataPointData.status=="ENABLE"?true:false
      }
      if($scope.meter?.meterType == "BACNET"){
        params = {
          ...params,
          bacnet:{
            objectId:$scope.dataPointData?.bacnet?.objectId,
            objectType:$scope.dataPointData?.bacnet?.objectType,
          }
        }
      }
      if($scope.meter?.meterType == "MODBUS"){
        params = {
          ...params,
          modbus:{
            slaveId:$scope.dataPointData?.modbus?.slaveId,
            registerId:$scope.dataPointData?.modbus?.registerId,
            registerType:$scope.dataPointData?.modbus?.registerType,
          }
        }
      }
      caller.call(Api.updateWaterDatapoint(id, params)).then(function(res) {
        // update the showing dataPoint
        if ($scope.meter && $scope.meter.id === meter.id && $scope.dataPoint && $scope.dataPoint.id === id) {
          $scope.selectData(meter, res);
        }
        //update meter.dataPoints
        Service.replaceArrItem(meter.dataPoints, res);
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        $scope.editDataPointWin.close();
      }, function(err) {
        $scope.btnStatus.saving = false;
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }
    $scope.confirmUpdateDataPoint = function() {
      $rootScope.loadingPage++;
      var id = $scope.dataPointData.id;
      var meter = $scope.menuSelectedMeter;
      caller.call(Api.updateDataPoint(id, $scope.dataPointData.name, $scope.dataPointData.scaler, $scope.dataPointData.status)).then(function(res) {
        //update the showing dataPoint
        if ($scope.meter && $scope.meter.id === meter.id && $scope.dataPoint && $scope.dataPoint.id === id) {
          $scope.selectData(meter, res);
        }
        //update meter.dataPoints
        Service.replaceArrItem(meter.dataPoints, res);
        $scope.searchMetersDataPoint()
        $rootScope.loadingPage--;
        $scope.editDataPointWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.confirmSaveDataPoint = function() {
      $scope.dataPointData.name = $scope.dataPointData.name.trim();
      $scope.dataPointData.scaler = parseFloat($scope.dataPointData.scaler);
      if (isNaN($scope.dataPointData.scaler)) {
        $scope.dataPointData.scaler = "";
        return;
      }
      if($scope.energyType==1){
        if ($scope.dataPointData.id) {
          $scope.confirmUpdateDataPoint();
        } else {
          $scope.confirmCreateDataPoint();
        }
      }else{
        if ($scope.dataPointData.id) {
          $scope.confirmUpdateWaterDataPoint();
        } else {
          $scope.confirmCreateWaterDataPoint()
        }
      }
    }

    $scope.createEguageDataPoints = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      $scope.meterMenu.close();
      var id = $scope.menuSelectedMeter.id;
      caller.call(Api.createEguageDataPoints(id)).then(function(res) {
        $scope.refreshMeter(id);
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.deleteDataPoint = function() {
      $scope.dataPointMenu.close();
      var dp = $scope.menuSelectedDataPoint;
      var m = $scope.menuSelectedMeter;
      //deleteWaterDatapoint
      if($scope.energyType==1){
        $rootScope.deletePopup.show("meter.deleteDataPoint", "meter.deleteDataPointDesc", dp.name, function() {
          caller.call(Api.deleteDataPoint(dp.id)).then(function(res) {
            if ($scope.dataPoint === dp)
              $scope.selectData($scope.meter, null);
            Service.deleteArrItem(m.dataPoints, dp.id);
            $rootScope.deletePopup.close();
          });
        }, function(err) {
          if (err === KEY.ignore)
            return;
          alert(Service.translate("error.generalDeleteFail"));
        });
      }else{//delete waterDataPoint
        $rootScope.deletePopup.show("meter.deleteDataPoint", "meter.deleteDataPointDesc", dp.name, function() {
          caller.call(Api.deleteWaterDatapoint(dp.id)).then(function(res) {
            if ($scope.dataPoint === dp)
              $scope.selectData($scope.meter, null);
            Service.deleteArrItem(m.dataPoints, dp.id);
            $rootScope.deletePopup.close();
          });
        }, function(err) {
          if (err === KEY.ignore)
            return;
          alert(Service.translate("error.generalDeleteFail"));
        });
      }
    }

    /* hierarchy tab */
    $scope.nodeExpandedIndex = function(nodeOrId) {
      if (typeof nodeOrId === "string") {
        for (var i = 0; i < $scope.expandedNodes.length; i++) {
          if ($scope.expandedNodes[i].id === nodeOrId)
            return i;
        }
        return -1;
      } else {
        return $scope.expandedNodes.indexOf(nodeOrId);
      }
    }

    $scope.clickNodeInHierarchy = function(node) {
      if (node.type === "N_DATAPOINT") {
        if (!$scope.editingHierarchy) {
          if ($scope.selectNodeInHierarchy(node)) {
            var cParent = getCommonParent(node);
            if (cParent) {
              $scope.expandedNodes.splice($scope.nodeExpandedIndex(cParent.id) + 1);
            } else {
              console.log("unexpected structure");
            }
          }
        }
      } else {
        $scope.expandHierarchyNode(node);
        $scope.hierarchyDataPoint = null;
      }
    }
    function getCommonParent(node) {
      if (node.parentId) {//isFirstLv dont have parentId, below have
        var parentExpandInd = $scope.nodeExpandedIndex(node.parentId);
        var parentNode = $scope.getNodeFromHierarchy(node.parentId);
        if (parentExpandInd > -1) {
          return parentNode;
        } else {
          return getCommonParent(parentNode);
        }
      }
    }
    $scope.expandHierarchyNode = function(node, callback) {
      var i = $scope.nodeExpandedIndex(node.id);
      if (i == -1) {  //expand it
        var cParent = getCommonParent(node);
        if (cParent) {
          $scope.expandedNodes.splice($scope.nodeExpandedIndex(cParent.id) + 1);
          $scope.expandedNodes.push(node);
        } else {  //1. nothing was expanded, 2. under different firstLv node
          $scope.expandedNodes = [node];  //since for both 1&2, the whole tree for node is closed, user can only expand from the firstLv
        }
        $rootScope.loadingPage++;
        caller.call(Api.getHierarchy(node.id, node.type)).then(function(res) {// dont replace the object?
          node.type = res.root.type;
          node.name = res.root.name;
          node.povUnit = res.root.povUnit;
          node.childrenCount = res.root.childrenCount;
          node.children = res.root.children;
          node.children.sort(nameSorter);
          node.children.forEach(c => c.parentId = node.id);
          if (callback)
            callback();
          $rootScope.loadingPage--;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          alert(Service.translate("error.generalGetDataFail"));
        });
      } else {  //collapse it
        $scope.expandedNodes.splice(i);
      }
    }

    function _getNodeFromHierarchy(id, node) {
      if (node.id === id) {
        return node;
      } else if (node.children) {
        for (var i = 0; i < node.children.length; i++) {
          var tmp = _getNodeFromHierarchy(id, node.children[i]);
          if (tmp)
            return tmp;
        }
      }
    }
    $scope.getNodeFromHierarchy = function(id) {
      return _getNodeFromHierarchy(id, {
        children: $scope.hierarchy,
      });
    }

    $scope.toggleEditHierarchy = function() {
      $scope.editingHierarchy = !$scope.editingHierarchy;
      $scope.hierarchyDataPoint = null;
      $scope.cancelEditVirtualDataPoint();
    }

    $scope.menuSelectedNode = null;
    $scope.openHierarchyMenu = function(node, $event) {
      var pos = window.innerHeight - $event.clientY < 120 ? "bottom right" : "top right";
      $event.stopPropagation();
      $scope.menuSelectedNode = node;
      $scope.hierarchyMenu.setOptions({ anchor: $("#meter .hierarchyTree #anchor" + node.id + " .editBtn"), position: pos });
      $scope.hierarchyMenu.open();
    }

    $scope.newNodeName = "";
    $scope.newPovUnitName = "";
    $scope.renameNode = null;
    $scope.updateHierarchyNodeName = function(node) {
      $scope.hierarchyMenu.close();
      $scope.renameNode = node ? node : $scope.menuSelectedNode;
      $scope.newNodeName = $scope.renameNode.name;
      $scope.renameWin.title(Service.translate("meter.renameNode"));
      setTimeout(function() {
        $scope.renameWin.open().center();
      });
    }
    $scope.confirmUpdateHierarchyNodeName = function() {
      $rootScope.loadingPage++;
      var n = $scope.renameNode;
      var name = $scope.newNodeName;
      caller.call(Api.updateHierarchyNodeName(n.id, n.type, name)).then(function(res) {
        n.name = name;
        $rootScope.loadingPage--;
        $scope.renameWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.calcChildData = {
      target: null,
      start: null,
      end: null,
      type: null
    }
    $scope.calcChildPickerOpt = {
      max: now,
      interval: 10,
      timeFormat: "h:mm tt",
    }
    $scope.calcChild = function() {
      $scope.hierarchyMenu.close();
      $scope.calcChildWin.title(Service.translate("meter.calcChild"));
      $scope.calcChildData.id = $scope.menuSelectedNode.id;
      $scope.calcChildData.end = now;
      $scope.calcChildData.start = now;
      $scope.calcChildData.target = $scope.menuSelectedNode.name;
      $scope.calcChildData.type = $scope.menuSelectedNode.type;
      $timeout(function() {
        $scope.calcChildWin.open().center();
      });
    }
    $scope.confirmCalcChild = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.calculatingChild = true
      var start = new Date($scope.calcChildData.start);
      start.setHours(0, 0, 0, 0);
      var end = new Date($scope.calcChildData.end);
      end.setHours(0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      caller.call(Api.calcChildPovs($scope.calcChildData.id, $scope.calcChildData.type, Service.getUnixTimestamp(start), Service.getUnixTimestamp(end))).then(function(res) {
        $rootScope.loadingPage--;
        $scope.btnStatus.calculatingChild = false;
        $scope.calcChildWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $scope.btnStatus.calculatingChild = false;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.recalcPovData = {
      target: null,
      start: null,
      end: null
    }
    $scope.recalcPovPickerOpt = {
      max: now,
      interval: 10,
      timeFormat: "h:mm tt",
    }
    $scope.recalcPovSummary = function() {
      $scope.hierarchyMenu.close();
      $scope.povRecalcSummaryWin.title(Service.translate("meter.recalcSummary"));
      $scope.recalcPovData.id = $scope.menuSelectedNode.id;
      $scope.recalcPovData.end = now;
      $scope.recalcPovData.start = now;
      $scope.recalcPovData.target = $scope.menuSelectedNode.name;
      $timeout(function() {
        $scope.povRecalcSummaryWin.open().center();
      });
    }
    $scope.confirmRecalcPovSummary = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.recalculatingPov = true
      var start = new Date($scope.recalcPovData.start);
      start.setHours(0, 0, 0, 0);
      var end = new Date($scope.recalcPovData.end);
      end.setHours(0, 0, 0, 0);
      end.setDate(end.getDate() + 1);
      caller.call(Api.recalcPovSummary($scope.recalcPovData.id, Service.getUnixTimestamp(start), Service.getUnixTimestamp(end))).then(function(res) {
        $rootScope.loadingPage--;
        $scope.btnStatus.recalculatingPov = false;
        $scope.povRecalcSummaryWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $scope.btnStatus.recalculatingPov = false;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.deviceFilter = function(){
        var txt = $scope.temperatureDpData.searchText?.trim()?.toLowerCase();
        if (txt) {
          return function(d){
            return d.serialId.toLowerCase().includes(txt);
          }
        } else {
          return function(d){
            return true;
          }
        }
    }

    $scope.temperatureDpData = {};
    $scope.createTemperatureDataPoint = function(){
      $scope.hierarchyMenu.close();
      $scope.temperatureDpData = {
        parent: $scope.menuSelectedNode,
        selectedMap: {},
        searchText: ""
      }
      $scope.addTemperatureDpWin.title(Service.translate("meter.createTemperatureDataPoint"));
      setTimeout(function() {
        $scope.addTemperatureDpWin.open().center();
      });
    }
    $scope.confirmCreateTemperatureDataPoint = function(){
      $rootScope.loadingPage++;
      let povId = $scope.temperatureDpData.parent.id;
      let calls = Object.keys($scope.temperatureDpData.selectedMap)
        .filter(id => $scope.temperatureDpData.selectedMap[id])
        .map(id => Api.bindDataPointToHierarchy($scope.temperatureDevices.find(d => d.id === id).serialId, id, false, "TEMPERATURE", povId));
      if (calls.length){
        caller.call(calls).then(function(res) {
          if ($scope.nodeExpandedIndex(povId) > -1) {
            $scope.refreshHierarchyByNodeId(povId);
          } else {
            var povNode = $scope.getNodeFromHierarchy(povId);
            $scope.expandHierarchyNode(povNode);
          }
          $scope.addTemperatureDpWin.close();
          $rootScope.loadingPage--;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          if (err.message) {
            alert(err.message);
          } else {
            alert(Service.translate("error.generalUpdate"));
          }
        });
      }
    }

    $scope.newChildName = "";
    $scope.addHierarchyChild = function() {
      $scope.hierarchyMenu.close();
      $scope.newChildName = "";
      $scope.addChildWin.title(Service.translate("meter.createChild"));
      setTimeout(function() {
        $scope.addChildWin.open().center();
      });
    }
    $scope.confirmAddHierarchyChild = function() {
      $rootScope.loadingPage++;
      var parent = $scope.menuSelectedNode;
      caller.call(Api.createHierarchyPov($scope.newChildName, parent.id, parent.type)).then(function(res) {
        if ($scope.nodeExpandedIndex(parent.id) > -1) {
          $scope.refreshHierarchyByNodeId(parent.id);
        } else {
          $scope.expandHierarchyNode(parent);
        }
        $rootScope.loadingPage--;
        $scope.addChildWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.deleteHierarchyNode = function() {
      $scope.hierarchyMenu.close();
      var node = $scope.menuSelectedNode;
      var tId = $scope.tenantId;
      if ($scope.menuSelectedNode.type === "N_POV") {
        $rootScope.deletePopup.show("meter.deletePOV", "meter.deletePOVDesc", node.name, function() {
          caller.call(Api.deleteHierarchyPov(node.id)).then(function(res) {
            $scope.reloadTenantHierarchy(tId);
            $rootScope.deletePopup.close();
          });
        }, function(err) {
          if (err === KEY.ignore)
            return;
          alert(Service.translate("error.generalDeleteFail"));
        });
      } else if ($scope.menuSelectedNode.type === "N_SITE") {
        $rootScope.deletePopup.show("meter.deleteSite", "meter.deleteSiteDesc", node.name, function() {
          caller.call(Api.deleteHierarchySite(node.id)).then(function(res) {
            $scope.reloadTenantHierarchy(tId);
            $rootScope.deletePopup.close();
          });
        }, function(err) {
          if (err === KEY.ignore)
            return;
          alert(Service.translate("error.generalDeleteFail"));
        });
      }
    }

    $scope.nodeTypeDropdownOpt = {
        autoWidth: true,
        dataSource: ["N_SITE", "N_POV"],
    }
    $scope.cityDropdownOpt = {
        autoWidth: true,
        dataSource: [],
        dataTextField: "name",
        dataValueField: "id"
    }
    $scope.cities = null;
    $scope.siteData = {};
    $scope.createFirstLvNode = function() {
      var initEdititeWin = function(){
        $scope.createFirstLvNodeWin.title(Service.translate("meter.createNode"));
        $scope.siteData = {
          name: "",
          address: "",
          cityId: $scope.cities[0].id,
          nodeType: "N_SITE",
          isEdit: false
        }
        setTimeout(function() {
          $scope.createFirstLvNodeWin.open().center();
        });
      }
      if ($scope.cities) {
        initEdititeWin();
      } else {
        $rootScope.loadingPage++;
        $scope.btnStatus.loadingCity = true;
        caller.call(Api.getCities()).then(function(res) {
          $scope.cities = res.map(function(c){
            return {
              id: c.id,
              name: c.country + "/" + c.city
            }
          });
          $scope.cities.sort(Service.getSorter("name"));
          $scope.cityDropdown.dataSource.data($scope.cities);
          $rootScope.loadingPage--;
          $scope.btnStatus.loadingCity = false;
          initEdititeWin();
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          $scope.btnStatus.loadingCity = false;
          alert(Service.translate("error.generalGetDataFail"));
        });
      }
    }
    $scope.confirmCreateFirstLvNode = function(){
      var promise = null;
      var tenant = $scope.tenantMap[$scope.tenantId];
      if ($scope.siteData.nodeType === 'N_POV') {
        promise = Api.createHierarchyPov($scope.siteData.name, tenant.id, tenant.type);
      } else if ($scope.siteData.nodeType === 'N_SITE') {
        promise = Api.createHierarchySite($scope.siteData.name, tenant.id, tenant.type, $scope.siteData.cityId, $scope.siteData.address);
      } else {
        return;
      }
      $rootScope.loadingPage++;
      $scope.btnStatus.saving = true;
      caller.call(promise).then(function(resId) {
        $scope.reloadTenantHierarchy(tenant.id);
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        $scope.createFirstLvNodeWin.close();
      }, function(err) {
        if (err === KEY.ignore)
          return;
        $rootScope.loadingPage--;
        $scope.btnStatus.saving = false;
        if (err.message) {
          alert(err.message);
        } else {
          alert(Service.translate("error.generalUpdate"));
        }
      });
    }

    $scope.virtualDPData = {};
    $scope.virtualDPDropdownOpt = {
      dataSource: ["VDS_DISABLE", "VDS_ENABLE"],
    }
    $scope.expressionTooltipOpt = {
      position: "top",
      width: "auto",
      callout: true,
      animation: {
        open: {
          effects: "zoom",
          duration: 150
        }
      },
      content: Service.translate("meter.expressionDesc"),
      show: function(e) {
        e.sender.popup.element.css({ "margin-top": "-5px" });
      }
    }
    $scope.addToExpression = function(txt, toId) {
      if ($scope.editingVirtualDP && txt) {
        if (toId) {
          $scope.virtualDPData.expression += "{id_" + txt + "}";
        } else {
          $scope.virtualDPData.expression += txt;
        }
      }
    }

    $scope.rounding = function(val, decimal){
      if (val === 0) {
        return "0";
      } else if (val < 0.01) {
        return "<0.01";
      } else {
        return Service.numFmt(val, decimal);
      }
    }

    $scope.getConsumptions = function(){
      $scope.virtualDPData.consumptions = {};
      var allDps = $scope.virtualDPData.expressionRefIds.map(refId => $scope.virtualDPData.dpMapByRefId[refId]).filter(dp => dp);
      var energyDps = [];
      var energyVDps = [];
      var waterDps = [];
      var waterVDps = [];
      var temperatureDps = [];
      if (allDps.length) {
        allDps.forEach(dp => {
          if (dp.dataType === "D_ENERGY"){
            energyDps.push(dp);
          } else if (dp.dataType === "D_VIRTUAL_DATA_POINT"){
            energyVDps.push(dp);
          } else if (dp.dataType === "D_WATER_METER"){
            waterDps.push(dp);
          } else if (dp.dataType === "D_WATER_VIRTUAL_DATA_POINT"){
            waterVDps.push(dp);
          } else if (dp.dataType === "D_TEMPERATURE"){
            temperatureDps.push(dp);
          }
        });

        var date = new Date();
        date.setHours(date.getHours(), 0, 0, 0);
        var endTime = Service.getUnixTimestamp(date);
        date.setHours(date.getHours() - 1);
        var startTime = Service.getUnixTimestamp(date);
        var tmpList = [energyDps, energyVDps, waterDps, waterVDps].filter(l => l.length);
        var calls = tmpList.map(l => Api.getConsumption(l.map(dp => dp.dataId), l[0].dataType, "RANGE", startTime, endTime));
        calls = calls.concat(tmpList.map(l => Api.getConsumption(l.map(dp => dp.dataId), l[0].dataType, "YESTERDAY")));
        if (temperatureDps.length) {
          date = new Date();
          date.setHours(0, 0, 0, 0);
          var endDate = Service.getUnixTimestamp(date);
          date.setDate(date.getDate() - 1);
          var startDate = Service.getUnixTimestamp(date);
          let ids = temperatureDps.map(dp => dp.dataId);
          calls.push(Api.getTemperatureSensorLog(ids, startDate, endDate, "PELLET_DAY"));
          calls.push(Api.getTemperatureSensorLog(ids, startTime, endTime, "PELLET_HOUR"));
        }

        caller.call(calls).then(function(res) {
          if (temperatureDps.length) {
            let lastTemperature = res.pop();
            let yesterdayTemperature = res.pop();
            lastTemperature.forEach((t, i) => {
              let refId = temperatureDps.find(dp => dp.dataId === t.deviceId).refId;
              $scope.virtualDPData.consumptions[refId] = {
                last: $scope.rounding(t.temperatures[0]?.value, 1),
                yesterday: $scope.rounding(yesterdayTemperature[i]?.temperatures[0]?.value, 1)
              }
            });
          }
          let count = res.length / 2;
          for (let i=0; i<count; i++){
            res[i].forEach((c, j) => {
              let refId = allDps.find(dp => dp.dataId === c.id).refId;
              $scope.virtualDPData.consumptions[refId] = {
                last: $scope.rounding(c.totalValue, 4),
                yesterday: $scope.rounding(res[i+count][j]?.totalValue, 4)
              }
            });
            
          }
        }, function(err) {
          alert(Service.translate("error.generalGetDataFail"));
        });
      }
    }

    $scope.getTotalConsumption = function(){
      $scope.virtualDPData.totalConsumption = {};
      var dataType = $scope.virtualDPData.meter === "ENERGY" ? "D_VIRTUAL_DATA_POINT" : "D_WATER_VIRTUAL_DATA_POINT";
      var date = new Date();
      date.setHours(date.getHours(), 0, 0, 0);
      var endTime = Service.getUnixTimestamp(date);
      date.setHours(date.getHours() - 1);
      var startTime = Service.getUnixTimestamp(date);
      caller.call([Api.getConsumption([$scope.virtualDPData.id], dataType, "RANGE", startTime, endTime), Api.getConsumption([$scope.virtualDPData.id], dataType, "YESTERDAY")]).then(function(res) {
        $scope.virtualDPData.totalConsumption.last = {
          time: Service.numFmt(res[0][0].timeConsuming * 1000),
          value: $scope.rounding(res[0][0].totalValue, 4),
        }
        $scope.virtualDPData.totalConsumption.yesterday = {
          time: Service.numFmt(res[1][0].timeConsuming * 1000),
          value: $scope.rounding(res[1][0].totalValue, 4),
        }
      }, function(err) {
        alert(Service.translate("error.generalGetDataFail"));
      });
    }

    var vLbl = Service.translate("meter.virtual");
    var thisHourLbl = Service.translate("meter.thisHour");
    var lastHourLbl = Service.translate("meter.lastHour");
    $scope.displayExpressionDpName = function(refId) {
      var dp = $scope.virtualDPData.dpMapByRefId[refId];
      if (dp) {
        var name = refId;
        if (dp.dataType.includes("VIRTUAL_DATA_POINT"))
          name += `(${vLbl})`;
        name += " - " + dp.name;
        return name;
      }
    }
    function getDataPointsFromExpression(){
      var txt = $scope.virtualDPData.expression.replace(/\s/g,'');
      var refIds = (txt.match(/\{id_[0-9]+\}/g) || []).map(id => id.substring(4, id.length-1));
      var tmpMap = $scope.virtualDPData.dpMapByRefId;
      var tmpIds = refIds.filter(id => !tmpMap[id]);
      $scope.virtualDPData.expressionRefIds = refIds;
      if (tmpIds.length) {
        caller.call(tmpIds.map(id => Api.getHierarchyDataPointByRefId(id))).then(function(res) {
          tmpIds.forEach((tId, i) => tmpMap[tId] = res[i]);
          $scope.getConsumptions();
        }, function(err) {
          alert(Service.translate("error.generalGetDataFail"));
        });
      }
    }
    var checkTimer = null;
    $scope.$watchCollection("[virtualDPData.expression, virtualDPData.meter, editingVirtualDP]", function(newArr, oldArr) {
      if (newArr[2]) {
        $timeout.cancel(checkTimer);
        checkTimer = $timeout(getDataPointsFromExpression, 300);
      }
    });

    $scope.meterDropdownOpt = {
      dataSource: ["ENERGY", "WATER"],
    }
    $scope.createVirtualDataPoint = function() {
      $scope.editingVirtualDP = true;
      $scope.btnStatus.vdpEditing = false;
      $scope.virtualDPData = {
        id: null,
        tenantId: $scope.tenantId,
        name: "",
        expression: "",
        status: "VDS_DISABLE",
        meter: "ENERGY",
        dpMapByRefId: {},
        expressionRefIds: [],
        consumptions: {},
      }
      // $scope.meterDropdownOpt.change();
    }
    function vdpEditErrorHandler(err, msg) {
      if (err === KEY.ignore)
        return;
      $rootScope.loadingPage--;
      $scope.btnStatus.vdpEditing = false;
      if (msg) {
        console.log(err.message);
        alert(msg);
      } else if (err.message) {
        alert(err.message);
      } else {
        alert(Service.translate("error.generalUpdate"));
      }
    }
    function testFormulaErrorHandler(err){
      vdpEditErrorHandler(err, Service.translate("error.invalidFormula"));
    }
    $scope.confirmCreateVirtualDataPoint = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.vdpEditing = true;
      if ($scope.virtualDPData.meter === "ENERGY") {
        caller.call(Api.testFormula($scope.virtualDPData.expression)).then(function(res) {
          caller.call(Api.createVirtualDataPoint($scope.virtualDPData.tenantId, $scope.virtualDPData.name, $scope.virtualDPData.expression, $scope.virtualDPData.status)).then(function(res) {
            $scope.virtualDataPoints.push(res);
            $rootScope.loadingPage--;
            // $scope.btnStatus.vdpEditing = false;
            // $scope.cancelEditVirtualDataPoint();
            $scope.finishVDPCreateUpdate(res, "ENERGY");
          }, vdpEditErrorHandler);
        }, testFormulaErrorHandler);
      } else if ($scope.virtualDPData.meter === "WATER") {
        caller.call(Api.testWaterFormula($scope.virtualDPData.expression)).then(function(res) {
          caller.call(Api.createWaterVirtualDataPoint($scope.virtualDPData.tenantId, $scope.virtualDPData.name, $scope.virtualDPData.expression, $scope.virtualDPData.status)).then(function(res) {
            $scope.waterVirtualDataPoints.push(res);
            $rootScope.loadingPage--;
            // $scope.btnStatus.vdpEditing = false;
            // $scope.cancelEditVirtualDataPoint();
            $scope.finishVDPCreateUpdate(res, "WATER");
          }, vdpEditErrorHandler);
        }, testFormulaErrorHandler);
      }
    }
    $scope.finishVDPCreateUpdate = function(res, type){
      $scope.updateVirtualDataPoint(res, type);
      $scope.showSaved();
    }
    $scope.cancelEditVirtualDataPoint = function() {
      $scope.editingVirtualDP = false;
      $scope.virtualDPData = {};
    }

    $scope.savedTimer = null;
    $scope.showSaved = function() {
      $scope.virtualDPData.saved = true;
      $timeout.cancel($scope.savedTimer);
      $scope.savedTimer = $timeout(() => $scope.virtualDPData.saved = false, 1500);
    }
    $scope.updateVirtualDataPoint = function(v, type) {
      $scope.editingVirtualDP = true;
      $scope.btnStatus.vdpEditing = false;
      var triggerExpressionChange = $scope.virtualDPData.id !== undefined;
      $scope.virtualDPData = {
        id: v.id,
        tenantId: $scope.tenantId,
        name: v.name,
        expression: v.expression,
        status: v.status,
        meter: type,
        dpMapByRefId: {},
        expressionRefIds: [],
        consumptions: {},
      }
      if (triggerExpressionChange)
        getDataPointsFromExpression();
      $scope.getTotalConsumption();
    }
    $scope.confirmUpdateVirtualDataPoint = function() {
      $rootScope.loadingPage++;
      $scope.btnStatus.vdpEditing = true;
      var id = $scope.virtualDPData.id;
      if ($scope.virtualDPData.meter === "ENERGY") {
        caller.call(Api.updateVirtualDataPoint(id, $scope.virtualDPData.name, $scope.virtualDPData.expression, $scope.virtualDPData.status)).then(function(res) {
          Service.replaceArrItem($scope.virtualDataPoints, res);
          $scope.refreshHierarchyByDpId(id);
          $rootScope.loadingPage--;
          $scope.finishVDPCreateUpdate(res, "ENERGY");
          // $scope.cancelEditVirtualDataPoint();
          // $scope.btnStatus.vdpEditing = false;
        }, vdpEditErrorHandler);
      } else if ($scope.virtualDPData.meter === "WATER") {
        caller.call(Api.updateWaterVirtualDataPoint(id, $scope.virtualDPData.name, $scope.virtualDPData.expression, $scope.virtualDPData.status)).then(function(res) {
          Service.replaceArrItem($scope.waterVirtualDataPoints, res);
          $scope.refreshHierarchyByDpId(id);
          $rootScope.loadingPage--;
          $scope.finishVDPCreateUpdate(res, "WATER");
          // $scope.cancelEditVirtualDataPoint();
          // $scope.btnStatus.vdpEditing = false;
        }, vdpEditErrorHandler);
      }
    }
    $scope.deleteVirtualDataPoint = function(v, type) {
      $scope.cancelEditVirtualDataPoint();
      $rootScope.deletePopup.show("meter.deleteVirtualDataPoint", "meter.deleteVirtualDataPointDesc", v.name, function() {
        caller.call(type === "ENERGY" ? Api.deleteVirtualDataPoint(v.id) : Api.deleteWaterVirtualDataPoint(v.id)).then(function(res) {
          if (type === "ENERGY") {
            Service.deleteArrItem($scope.virtualDataPoints, v.id);
          } else if (type === "WATER") {
            Service.deleteArrItem($scope.waterVirtualDataPoints, v.id);
          }
          $scope.refreshHierarchyByDpId(v.id);
          if ($scope.hierarchyDataPoint && $scope.hierarchyDataPoint.id === v.id)
            $scope.hierarchyDataPoint = null;
          $rootScope.deletePopup.close();
        });
      }, function(err) {
        if (err === KEY.ignore)
          return;
        alert(Service.translate("error.generalDeleteFail"));
      });
    }

    $scope.confirmEditVirtualDataPoint = function() {
      if ($scope.virtualDPData.id) {
        $scope.confirmUpdateVirtualDataPoint();
      } else {
        $scope.confirmCreateVirtualDataPoint();
      }
    }

    $scope.refreshHierarchyByDpId = function(dpId) {
      if ($scope.expandedNodes.length) {
        for (var i=$scope.expandedNodes.length-1; i>-1; i--){
          var expandedNode = $scope.expandedNodes[i];
          if (expandedNode.children.find(c => c.datapoint.dataId === dpId)) {
            //force get data again
            $scope.expandedNodes.splice(i, $scope.expandedNodes.length);
            $scope.expandHierarchyNode(expandedNode);
            break;
          }
        }
      }
    }
    $scope.refreshHierarchyByNodeId = function(nodeId, expandCallback) {
      var i = $scope.nodeExpandedIndex(nodeId);
      if (i != -1) {
        //force get data again
        var expandedNode = $scope.expandedNodes[i];
        $scope.expandedNodes.splice(i);
        $scope.expandHierarchyNode(expandedNode, expandCallback);
      }
    }

    // mainly for display data when selecting datapoint in hierarchy
    $scope.selectNodeInHierarchy = function(node) {//TODOricky checked, may not able to show dp info if not get data yet
      // should only select dataPoint
      if (!$scope.temperatureDevices)
        return;
      var dpId = node.datapoint.dataId;
      var dataType = node.datapoint.dataType;
      if ($scope.hierarchyDataPoint?.id !== dpId) {
        $scope.hierarchyDataPoint = null;
        if (dataType === "D_TEMPERATURE") {
          $scope.hierarchyDataPoint = $scope.temperatureDevices.find(d => d.id === dpId);
          $scope.hierarchyDataPoint.isVirtual = false;
          $scope.hierarchyDataPoint.type = "TEMPERATURE";
          $scope.hierarchyDataPoint.hierarchyDatapoint = { refId: node.datapoint.refId };
        } else {
          var tmp = {};
          if (dataType === "D_ENERGY") {
            tmp.type = "ENERGY";
            tmp.promise = Api.getDataPoint(dpId);
          } else if (dataType === "D_VIRTUAL_DATA_POINT") {
            tmp.type = "ENERGY";
            tmp.isVirtual = true;
            tmp.promise = Api.getVirtualDataPoint(dpId);
          } else if (dataType === "D_WATER_METER") {
            tmp.type = "WATER";
            tmp.promise = Api.getWaterDataPoint(dpId);
          } else if (dataType === "D_WATER_VIRTUAL_DATA_POINT") {
            tmp.type = "WATER";
            tmp.isVirtual = true;
            tmp.promise = Api.getVirtualWaterDataPoint(dpId);
          }
          if (tmp.type) {
            $rootScope.loadingPage++;
            caller.call(tmp.promise).then(function(res) {
              var meterPromise = dataType === "D_ENERGY" ? Api.getMeter(res.meterId) : dataType === "D_WATER_METER" ? Api.getWaterMeter(res.meterId) : null;
              if (meterPromise) {
                $rootScope.loadingPage++;
                caller.call(meterPromise).then(function(meterRes) {
                  res.meterSerial = meterRes.serial;
                  $rootScope.loadingPage--;
                }, function(err) {
                  if (err === KEY.ignore)
                    return;
                  $rootScope.loadingPage--;
                  if (err.message) {
                    alert(err.message);
                  } else {
                    alert(Service.translate("error.generalGetDataFail"));
                  }
                });
              }
              res.type = tmp.type;
              res.isVirtual = tmp.isVirtual;
              $scope.hierarchyDataPoint = res;
              $rootScope.loadingPage--;
            }, function(err) {
              if (err === KEY.ignore)
                return;
              $rootScope.loadingPage--;
              if (err.message) {
                alert(err.message);
              } else {
                alert(Service.translate("error.generalGetDataFail"));
              }
            });
          } else {
            console.log("unexpected dataType", dataType);
          }
        }
      }
      return $scope.hierarchyDataPoint;
    }

    $scope.hierarchyIdLbl = Service.translate("meter.hierarchyId") + ": ";
    $scope.dragOpt = {
      autoScroll: true,
      distance: 1,
      filter: ".editing .canDrag",
      cursorOffset: { top: 0, left: 0 },
      hint: function(e) {
        return $('<div class="hierarchyHint rowStart">' + e.html() + '</div>');
      },
      dragstart: function(e) {
        $scope.$apply(function() {
          $scope.isDraggingDP = true;
        });
      },
      dragend: function(e) {
        $scope.$apply(function() {
          $scope.isDraggingDP = false;
        });
      }
    }
    $scope.dropOpt = {
      filter: ".canDrop",//only allow to drop in POV node
      drop: function(e) { // assuming temperature dp will not bind in this way
        var povId = e.dropTarget.attr("id");
        var dataId = e.draggable.currentTarget.attr("id");
        var meterType = e.draggable.currentTarget.attr("type");
        var povNode = $scope.getNodeFromHierarchy(povId);
        var isVirtual = false;
        var dp = (meterType === "WATER" ? $scope.waterVirtualDataPoints : meterType === "ENERGY" ? $scope.virtualDataPoints : []).find(function(vdp) {
          return vdp.id === dataId;
        });
        if (dp) {
          isVirtual = true;
        } else {
          var tmpMeterList = (meterType === "WATER" ? $scope.waterMeters : meterType === "ENERGY" ? $scope.meters : []);
          for (var i = 0; i < tmpMeterList.length; i++) {
            if (tmpMeterList[i].dataPoints) {
              dp = tmpMeterList[i].dataPoints.find(function(dp) {
                return dp.id === dataId;
              });
              if (dp)
                break;
            }
          }
        }
        if (!dp) {
          console.log("unexpected error, dp not found");
          return;
        }
        $scope.$apply(function() {
          $rootScope.loadingPage++;
        });
        caller.call(Api.bindDataPointToHierarchy(dp.name, dataId, isVirtual, meterType, povId)).then(function(res) {
          var callRename = function() {
            $scope.updateHierarchyNodeName($scope.getNodeFromHierarchy(res.id));
          }
          if ($scope.nodeExpandedIndex(povId) > -1) {
            $scope.refreshHierarchyByNodeId(povId, callRename);
          } else {
            $scope.expandHierarchyNode(povNode, callRename);
          }
          $rootScope.loadingPage--;
        }, function(err) {
          if (err === KEY.ignore)
            return;
          $rootScope.loadingPage--;
          if (err.message) {
            alert(err.message);
          } else {
            alert(Service.translate("error.generalUpdate"));
          }
        });
      }
    }

    $scope.unbindDataPoint = function(node) {
      if (node.type !== "N_DATAPOINT")
        return;
      var parentNode = $scope.getNodeFromHierarchy(node.parentId);
      $rootScope.confirmPopup.show("meter.unbindDataPoint", "meter.unbindDataPointDesc", node.name, function() {
        caller.call(Api.unbindDataPointFromHierarchy(node.id, node.parentId, parentNode.type)).then(function(res) {
          $scope.refreshHierarchyByNodeId(node.parentId);
          $rootScope.confirmPopup.close();
        }, function(err) {
          if (err === KEY.ignore)
            return;
          if (err.message) {
            alert(err.message);
          } else {
            alert(Service.translate("error.generalDeleteFail"));
          }
        });
      });
    }
    /* hierarchy tab */

    var resizeTimer = null;
    $(window).resize(function() {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function() {
        $scope.chart.refresh();
      }, 200);
    });

    $scope.$on('$destroy', function() {
      console.log("meterController destroy");
      caller.cancel();
      $interval.cancel(queryTimer);
    });
  }
})();